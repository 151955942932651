import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AccountCredit, ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCredits(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<AccountCredit>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
  if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/credits?${query}`
    return httpService.get(url)
  },
  addAccountCredit(params: AccountCredit): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/accountCredit/addAccountCredit`

    return httpService.post(url, {
      ...params,
    })
  },
  voidAccountCredit(params: AccountCredit): Promise<AxiosResponse<ApiResult>>  {
    const url = `https://${baseUrl()}/accountCredit/voidAccountCredit`

    return httpService.post(url, {
      ...params,
    })
  },
}
