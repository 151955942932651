var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"contact-detail"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.op(_vm.row.actionProps, 'name'))+" ")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-chip',{staticStyle:{"right":"5px"},attrs:{"color":_vm.$cr.theme.grayLight,"label":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.op(_vm.row.actionProps, 'partnerType'))+" ")]),(_vm.op(_vm.referredToCompany, 'onboardingStatusType'))?_c('v-chip',{staticStyle:{"right":"5px"},attrs:{"color":_vm.$cr.theme.grayLight,"label":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.op(_vm.referredToCompany, 'onboardingStatusType'))+" ")]):_vm._e(),(_vm.op(_vm.referredToCompany, 'spab'))?_c('CRIcon',{staticStyle:{"margin":"0 0 -10px 8px"},attrs:{"height":"42","width":"42","color":"primary"}},[_vm._v(" spab ")]):_vm._e()],1),_c('span',[_vm._v(" Phone: "),_c('a',{attrs:{"id":`${_vm.id}-referral-detail-href-phone-number`,"href":`tel:${_vm.op(_vm.row.actionProps, 'phone')}`}},[_vm._v(" "+_vm._s(_vm.phoneFormatFilter(_vm.op(_vm.referredToCompany, 'companyPhone')))+" ")]),_c('br')]),_c('span',[_vm._v(" Email: "),_c('a',{attrs:{"id":`${_vm.id}-referral-detail-href-email`,"href":`mailto:${_vm.op(_vm.referredToCompany, 'companyEmail')}`}},[_vm._v(" "+_vm._s(_vm.op(_vm.referredToCompany, 'companyEmail'))+" ")]),_c('br')]),_c('p',[_vm._v(" Address: "+_vm._s(_vm.op(_vm.referredToCompany, 'address/addressName'))+" "),_c('br')])]),(_vm.nonRejectedReferrals.length > 0)?_c('v-flex',{attrs:{"xs6":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.nonRejectedReferrals.filter(
                (referral) =>
                  _vm.op(referral, 'companyId') === _vm.op(_vm.row, 'actionProps/id') ||
                  _vm.op(referral, 'companyId') ===
                    _vm.op(_vm.row, 'actionProps/companyId')
              )),function(referral,referralKey){return _c('v-flex',{key:referralKey,attrs:{"xs12":"","fill-height":""}},[(!_vm.showPaymentWindow)?_c('v-layout',{attrs:{"row":"","wrap":"","referral-action-container":""}},[_c('v-flex',{attrs:{"xs5":"","subheading":"","referral-label":""}},[_c('div',[_vm._v("Referral # "+_vm._s(referral.managedId))]),_c('small',{staticClass:"referral-amount"},[_vm._v(" "+_vm._s(_vm.currencyFilter( (referral.referralStatus === 'reoffered' ? referral.originalAmount : referral.referralAmount) || 0 ))+" ")])]),_c('v-flex',{attrs:{"xs7":"","text-xs-center":""}},[(_vm.op(referral, 'referralStatus') !== 'accepted')?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`${_vm.id}-referral-detail-button-accept-referral-${referralKey}`,"loading":_vm.loading},on:{"click":() => _vm.acceptReferral(referral.reservationId)}},[_vm._v(" Mark Accepted ")]):_vm._e(),(
                      _vm.isReferralRejectionReasonV2Enabled &&
                      _vm.op(referral, 'referralStatus') !== 'accepted'
                    )?_c('CRButton',{attrs:{"id":`${_vm.id}-referral-reject-button-${referralKey}`,"outline":"","text-color":"red","loading":_vm.loading,"disabled":!_vm.reservation.active},on:{"click":function($event){return _vm.openRejectReferralSidebar(referral)}}},[_vm._v(" Mark Declined ")]):(_vm.op(referral, 'referralStatus') !== 'accepted')?_c('ReferralRejectDialog',{attrs:{"id":`${_vm.id}-referral-detail-button-reject-referral-${referralKey}`,"loading":_vm.loading,"active":_vm.reservation.active,"outline":"","text":"Mark Declined"},on:{"reject":(reasonType, notes) =>
                        _vm.rejectReferral(
                          referral,
                          referral.reservationId,
                          reasonType,
                          notes
                        )}}):_vm._e(),(_vm.op(referral, 'referralStatus') === 'accepted')?_c('v-btn',{attrs:{"id":`${_vm.id}-referral-detail-button-accepted-${referralKey}`,"color":"success","outline":""}},[_c('v-icon',[_vm._v("check_circle_outline")]),_c('strong',[_vm._v("Accepted")])],1):_vm._e(),(
                      _vm.op(referral, 'referralStatus') === 'accepted' &&
                      _vm.op(referral, 'balance') > 0
                    )?_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`${_vm.id}-referral-detail-button-show-payment-window-${referralKey}`,"loading":_vm.loading},on:{"click":function($event){_vm.showPaymentWindow = true}}},[_c('strong',[_vm._v("Pay Affiliate")])]):_vm._e()],1)],1):_vm._e(),_c('v-form',{ref:"pay-affiliate-form",refInFor:true,attrs:{"lazy-validation":true}},[(_vm.showPaymentWindow)?_c('v-layout',{attrs:{"row":"","wrap":"","referral-action-container":""}},[_c('v-flex',{attrs:{"xs12":"","subheading":"","referral-label":""}},[_c('br'),_c('div',[_vm._v("Record Affiliate Payment")]),_c('br')]),_c('v-flex',{attrs:{"xs4":"","offset-xs1":""}},[_c('CRInput',{attrs:{"id":`${_vm.id}-referral-detail-input-balance-${referralKey}`,"value":referral.balance,"disabled":"","prefix":"$","label":"Balance"}})],1),_c('v-flex',{attrs:{"xs4":"","offset-xs2":""}},[_c('CRInput',{attrs:{"id":`${_vm.id}-referral-detail-input-payment-amount-${referralKey}`,"label":"Payment Amount","error-messages":_vm.paymentAmountErrors(referral),"rules":[
                        _vm.isRequired(true, _vm.isNotEmpty, {
                          req: 'Payment Amount Is Required',
                          error: 'Payment Amount Is Required',
                        }),
                      ],"type":"number","prefix":"$"},model:{value:(_vm.paymentAmount),callback:function ($$v) {_vm.paymentAmount=$$v},expression:"paymentAmount"}})],1),_c('v-flex',{attrs:{"xs10":"","offset-xs1":""}},[_c('CRSelect',{attrs:{"id":`${_vm.id}-referral-detail-select-payment-type-${referralKey}`,"items":_vm.paymentTypes,"label":"Payment Type","item-text":"label","item-value":"paymentTypeId","rules":_vm.formSubmitted
                          ? [
                              _vm.isRequired(true, _vm.isNotEmpty, {
                                req: 'Payment Type Required',
                                error: 'Payment Type Required',
                              }),
                            ]
                          : []},model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}})],1),_c('v-flex',{attrs:{"xs10":"","offset-xs1":""}},[_c('CRInput',{attrs:{"id":`${_vm.id}-referral-detail-input-reference-number-${referralKey}`,"label":"Reference Number","rules":_vm.formSubmitted
                          ? [
                              _vm.isRequired(true, _vm.isNotEmpty, {
                                req: 'Reference Number Is Required',
                                error: 'Reference Number Is Required',
                              }),
                            ]
                          : []},model:{value:(_vm.refNumber),callback:function ($$v) {_vm.refNumber=$$v},expression:"refNumber"}})],1),_c('v-flex',{attrs:{"xs10":"","offset-xs1":""}},[_c('CRInput',{attrs:{"id":`${_vm.id}-referral-detail-input-payment-notes-${referralKey}`,"multi-line":"","label":"Payment Notes","rules":[
                        _vm.isRequired(true, _vm.isNotEmpty, {
                          req: 'Payment Note Is Required',
                          error: 'Payment Note Is Required',
                        }),
                      ]},model:{value:(_vm.paymentNotes),callback:function ($$v) {_vm.paymentNotes=$$v},expression:"paymentNotes"}})],1),_c('v-flex',{attrs:{"xs10":"","offset-xs1":""}},[_c('v-checkbox',{attrs:{"id":`${_vm.id}-referral-detail-checkbox-send-payment-email-${referralKey}`,"label":"Send Payment Email"},model:{value:(_vm.sendPaymentEmail),callback:function ($$v) {_vm.sendPaymentEmail=$$v},expression:"sendPaymentEmail"}})],1),_c('v-flex',{attrs:{"xs10":"","offset-xs1":"","text-xs-right":"","pay-affiliate-actions":""}},[_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":`${_vm.id}-referral-detail-show-payment-window-${referralKey}`},on:{"click":function($event){_vm.showPaymentWindow = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`${_vm.id}-referral-detail-button-submit-form-pay-${referralKey}`,"disabled":referral.balance < parseFloat(_vm.paymentAmount || 0)},on:{"click":() => _vm.submitForm(referral, referralKey)}},[_vm._v(" Pay ")]),_c('br'),_c('br')],1)],1):_vm._e(),(_vm.responseMessage)?_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"text-xs-center red--text"},[_vm._v(" "+_vm._s(_vm.responseMessage)+" ")])],1):_vm._e()],1)],1)}),1)],1):_vm._e()],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":true,"height":"2"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }