<template>
  <div :class="containerClass">
    <v-container>
      <v-form ref="credit-form" :lazy-validation="true">
        <v-layout row>
          <h3>
            Add Account Credit
            <span v-if="rows.length">({{ rows.length }})</span>
          </h3>
        </v-layout>
        <v-layout v-if="rows.length" row>
          <v-chip
            v-for="selectedItem in rows"
            :key="selectedItem.item.managedId"
            class="reservation-chip"
            color="primary"
            text-color="white"
            close
            @input="remove(selectedItem.item)"
          >
            {{ selectedItem.item.managedId }}
          </v-chip>
        </v-layout>
        <v-layout row align-start>
          <v-flex>
            <CRInput
              :id="`add-account-credit-input-balance`"
              v-model="balance"
              disabled
              prefix="$"
              :label="
                Object.keys(row).length && !rows.length
                  ? 'Balance'
                  : 'Total Balance'
              "
            />
          </v-flex>
          <v-flex offset-xs1>
            <CRInput
              :id="`add-account-credit-input-amount`"
              v-model="amount"
              type="number"
              prefix="$"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Amount Is Required',
                  error: 'Amount Is Required',
                }),
              ]"
              :label="
                Object.keys(row).length && !rows.length
                  ? 'Amount'
                  : 'Total Amount'
              "
            />
          </v-flex>
        </v-layout>
        <v-flex>
          <CRInput
            :id="`add-account-credit-input-notes`"
            v-model="notes"
            multi-line
            label="Notes"
          />
        </v-flex>
        <v-flex>
          <v-checkbox
            :id="`add-account-credit-checkbox-send-email`"
            v-model="sendEmail"
            class="send-email-checkbox"
            label="Send Email"
          />
        </v-flex>
        <v-layout row class="actions-row">
          <v-spacer />
          <v-btn
            :id="`add-account-credit-button-cancel`"
            class="btn-secondaryaction"
            :loading="loading"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :id="`add-account-credit-button-add-credit`"
            class="btn-primaryaction"
            :loading="loading"
            @click="submit"
          >
            Add Credit
          </v-btn>
        </v-layout>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import credits from '@/services/credits'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import * as logger from '@/utils/logger'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    resDetailVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      amount: '',
      notes: '',
      sendEmail: false,
      loading: false,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    balance() {
      let balanceOut = null
      if (this.rows.length && !Object.keys(this.row).length) {
        // multi-select version
        this.rows.forEach((selection) => {
          if (balanceOut)
            balanceOut = Number(balanceOut) + Number(selection.item.balance)
          else balanceOut = Number(selection.item.balance)
        })
      } else {
        // detail version
        balanceOut = this.row.balance
      }

      return balanceOut ? balanceOut.toFixed(2) : (0.004).toFixed(2)
    },
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    remove(item) {
      this.$emit('remove-item', item)
    },
    cancel() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    containerClass() {
      if (this.resDetailVersion) return ''
      return Object.keys(this.row).length && !this.rows.length
        ? 'action-box'
        : ''
    },
    async submit() {
      this.loading = true
      this.formSubmitted = true
      await this.$forceUpdate()
      const isValid = await this.$refs['credit-form'].validate()
      if (!isValid) {
        this.loading = false
        return
      }

      let res
      try {
        res = await credits.addAccountCredit({
          userId: this.currentUser.userId,
          companyId: this.currentUser.companyId,
          amount: this.amount,
          sendEmail: this.sendEmail,
          notes: this.notes,
        })
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error Submitting Account Credit, Please try again',
        })
        this.loading = false
        logger.error(err)
        return
      }

      if (!res || !res.data.successful) {
        this.loading = false
        this.showAlert({
          type: 'error',
          message: res.data?.message
            ? res.data.message
            : 'Error Submitting Account Credit, Please try again',
        })
        logger.error(res)
      } else {
        this.showAlert({
          message: 'Account Credit successfully added!',
        })
        this.loading = false
        this.cancel()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-box {
  width: 50%;
  margin: auto;
  border: 1px solid $gray-light;
  border-radius: 4px;
}

h3 {
  margin-bottom: 15px;
}

.actions-row .v-btn:last-of-type {
  margin-right: 0;
}

.reservation-chip {
  margin: 4px 15px 20px 0;
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.send-email-checkbox {
  margin-top: 0;
}
</style>
