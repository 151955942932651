<template>
  <v-dialog v-model="dialog" width="750">
    <template #activator="{ on }">
      <v-btn
        :id="$attrs.id"
        :loading="loading"
        :large="large"
        color="error"
        :outline="outline"
        :disabled="!active"
        v-on="on"
      >
        {{ text }}
      </v-btn>
    </template>
    <v-card class="cr-modal">
      <v-form ref="form">
        <v-card-title><h2>Reject Referral</h2></v-card-title>
        <v-card-text>
          <v-layout align-center row wrap>
            <v-flex xs12 class="terms-text-box">
              <CRSelect
                v-model="newReferralRejectReason"
                label="Why are you rejecting the referral?"
                :items="referralRejectionReasonTypes"
                item-text="label"
                item-value="id"
                placeholder="Please select a reason for rejecting this booking"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Reason is Required',
                    error: 'Reason is Required',
                  }),
                ]"
              />
              <v-textarea
                id="referral-reject-text-area-notes"
                v-model="notes"
                class="pt-2"
                rows="2"
                flat
                placeholder="Add reasons for rejection here."
                solo
                small
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end>
            <div class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="coachrail-limited-modal-button-cancel"
                  color="grey darken"
                  outline
                  @click="cancelDialog"
                >
                  Cancel
                </v-btn>
                <v-btn
                  id="coachrail-limited-modal-button-request-upgrade"
                  color="error"
                  :disabled="!active"
                  @click="decline"
                >
                  Reject
                </v-btn>
              </div>
            </div>
          </v-layout>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    loading: {
      type: Boolean,
    },
    text: {
      type: String,
      default: 'Reject',
    },
    outline: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      notes: null,
      newReferralRejectReason: null,
      referralRejectionReasonTypes: [],
    }
  },
  mounted() {
    this.setReferralRejectionReasonTypes()
  },
  methods: {
    cancelDialog() {
      this.newReferralRejectReason = null
      this.notes = null
      this.$refs['form'].reset()
      this.dialog = false
    },
    async decline() {
      const isValid = await this.$refs['form'].validate()
      if (!isValid) {
        return
      }
      this.$emit('reject', this.newReferralRejectReason, this.notes)
    },
    async setReferralRejectionReasonTypes() {
      let res = await this.$store.dispatch('types/getreferralRejectionReason')
      this.referralRejectionReasonTypes = res.data
    },
    isRequired,
    isNotEmpty,
  },
}
</script>
