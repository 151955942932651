<template>
  <v-layout>
    <div class="radius-container">
      <v-layout row>
        <div class="select">
          <select ref="radiusScope" class="radius-selector" @change="setFilter">
            <option value="lte">Around</option>
            <option value="gte">Outside</option>
          </select>
        </div>
        &nbsp;
        <div class="select">
          <select
            ref="radiusAmount"
            class="radius-selector"
            @change="setFilter"
          >
            <option
              v-for="(radiusSelectionValue,
              radiusSelectionIndex) in radiusSelectionValues"
              :key="radiusSelectionIndex"
              :value="radiusSelectionValue"
            >
              {{ radiusSelectionValue }}
            </option>
          </select>
        </div>
        &nbsp;
        <div class="select">
          <select
            ref="radiusMarket"
            class="radius-selector"
            @change="setFilter"
          >
            <option :value="0" class="radius-selector-option" />
            <option
              v-for="(market, marketIndex) in markets"
              :key="marketIndex"
              :value="market.marketId"
            >
              {{ market.marketName }}
            </option>
          </select>
        </div>
      </v-layout>
    </div>
  </v-layout>
</template>

<script>
import markets from '@/services/markets'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
  },
  data() {
    return {
      markets: [],
      radiusSelectionValues: [10, 50, 100, 300, 500],
      marketDistanceFilter: {
        column: {
          _t_id: '6b01a5c0',
          prop: 'distanceFromMarket',
          filterType: '',
        },
      },
      marketLocaitionFilter: {
        column: {
          _t_id: 'c22dbed8',
          prop: 'marketId',
          filterType: 'eq',
        },
      },
      activeMarketDistanceFilter: undefined,
      activeMarketLocationFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const marketsData = await markets.tableView({
      pageSize: -1,
    })
    this.markets = marketsData?.data?.resultList || []
    this.markets.sort((a, b) => (a.name > b.name ? 0 : -1))
    this.$refs.radiusAmount.value = 100
    const activeMarketDistanceFilter = this.findFilter(
      this.marketDistanceFilter
    )
    const activeMarketLocationFilter = this.findFilter(
      this.marketLocaitionFilter
    )
    if (activeMarketDistanceFilter && activeMarketLocationFilter) {
      this.activeMarketDistanceFilter = activeMarketDistanceFilter
      this.activeMarketLocationFilter = activeMarketLocationFilter
      this.$nextTick(() => {
        this.$refs.radiusAmount.value = activeMarketDistanceFilter.value
        this.$refs.radiusScope.value =
          activeMarketDistanceFilter.column.filterType
        this.$refs.radiusMarket.value = activeMarketLocationFilter.value
        this.addFilter()
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      const { radiusScope, radiusAmount, radiusMarket } = this.$refs
      const { value: radiusScopeValue } = radiusScope
      const { value: radiusAmountValue } = radiusAmount
      const { value: radiusMarketValue } = radiusMarket
      if (radiusScopeValue && radiusAmountValue && radiusMarketValue) {
        if (this.findFilter(this.activeMarketDistanceFilter)) {
          this.filters.remove(this.activeMarketDistanceFilter)
        }
        if (this.findFilter(this.activeMarketLocationFilter)) {
          this.filters.remove(this.activeMarketLocationFilter)
        }
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addMarketDistanceFilter(radiusScopeValue, radiusAmountValue)
        this.addMarketLocationFilter(radiusMarketValue)
        this.$emit('make-dirty-request')
        this.addFilter()
      }
    },
    addMarketDistanceFilter(radiusScope, radiusAmount) {
      const newFilter = deepClone(this.marketDistanceFilter)
      newFilter.hideOnFilterBar = true
      newFilter.column.filterType = radiusScope
      newFilter.value = radiusAmount
      this.activeMarketDistanceFilter = newFilter
      this.filters.add(this.grandParentFilter, newFilter)
    },
    addMarketLocationFilter(radiusMarket) {
      const newFilter = deepClone(this.marketLocaitionFilter)
      newFilter.hideOnFilterBar = true
      newFilter.value = radiusMarket
      this.activeMarketLocationFilter = newFilter
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.radius-container {
  // height: 34px;
  padding: 4px;
  margin: 4px;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
  // width: 230px;
}

.radius-control-container {
  padding: 4px;
}

.radius-selector {
  width: 100px;
  font-weight: 600;
  color: $primary !important;
  background-color: $blue-pale;
  border: 1px solid $primary;
  border-radius: 2px;
  appearance: menulist;
}

.radius-value-input {
  height: 30px !important;
  color: $primary;
  background-color: $white;

  ::after {
    color: $primary;
    content: '\e91b';
  }
}

// Vuetify Override
::v-deep .v-text-field.v-text-field--solo .v-input__control {
  min-height: 42px;
}
</style>
