var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.containerClass},[_c('v-container',[_c('v-form',{ref:"credit-form",attrs:{"lazy-validation":true}},[_c('v-layout',{attrs:{"row":""}},[_c('h3',[_vm._v(" Add Account Credit "),(_vm.rows.length)?_c('span',[_vm._v("("+_vm._s(_vm.rows.length)+")")]):_vm._e()])]),(_vm.rows.length)?_c('v-layout',{attrs:{"row":""}},_vm._l((_vm.rows),function(selectedItem){return _c('v-chip',{key:selectedItem.item.managedId,staticClass:"reservation-chip",attrs:{"color":"primary","text-color":"white","close":""},on:{"input":function($event){return _vm.remove(selectedItem.item)}}},[_vm._v(" "+_vm._s(selectedItem.item.managedId)+" ")])}),1):_vm._e(),_c('v-layout',{attrs:{"row":"","align-start":""}},[_c('v-flex',[_c('CRInput',{attrs:{"id":`add-account-credit-input-balance`,"disabled":"","prefix":"$","label":Object.keys(_vm.row).length && !_vm.rows.length
                ? 'Balance'
                : 'Total Balance'},model:{value:(_vm.balance),callback:function ($$v) {_vm.balance=$$v},expression:"balance"}})],1),_c('v-flex',{attrs:{"offset-xs1":""}},[_c('CRInput',{attrs:{"id":`add-account-credit-input-amount`,"type":"number","prefix":"$","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Amount Is Required',
                error: 'Amount Is Required',
              }),
            ],"label":Object.keys(_vm.row).length && !_vm.rows.length
                ? 'Amount'
                : 'Total Amount'},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),_c('v-flex',[_c('CRInput',{attrs:{"id":`add-account-credit-input-notes`,"multi-line":"","label":"Notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1),_c('v-flex',[_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"id":`add-account-credit-checkbox-send-email`,"label":"Send Email"},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}})],1),_c('v-layout',{staticClass:"actions-row",attrs:{"row":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"btn-secondaryaction",attrs:{"id":`add-account-credit-button-cancel`,"loading":_vm.loading},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`add-account-credit-button-add-credit`,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Add Credit ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }