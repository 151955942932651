<template>
  <div class="contact-detail">
    <v-card-title primary-title>
      <v-layout>
        <v-flex xs4>
          <b class="pr-1">Customer:</b>
          <span class="headline">
            {{ op(row, 'customer/firstName') }}
            {{ op(row, 'customer/lastName') }}
          </span>
        </v-flex>
        <v-flex xs5 offset-xs3>
          <span class="contact-row">
            <b>Phone:</b>
            <a id="customer-detail-href-phone" @click="() => flexCall(row)">
              {{ phoneFormatFilter(op(row, 'customer/phone')) }}
            </a>
            <br />
          </span>
          <span class="contact-row">
            &nbsp;&nbsp;
            <b>Email:</b>
            <a
              id="customer-href-email-to"
              :href="`mailto:${op(row, 'customer/email')}`"
            >
              {{ op(row, 'customer/email') }}
            </a>
            <br />
          </span>
          <span v-if="op(row, 'customer/isBanned')" class="contact-row">
            <b :style="`color: ${$cr.theme.error}`">Banned:</b>
            Yes
            <br />
          </span>
          <span v-if="customerAccountName" class="contact-row">
            &nbsp;&nbsp;
            <b>Customer Account:</b>
            {{ customerAccountName }}
            <br />
          </span>
          <span v-if="row.organization || organization" class="contact-row">
            &nbsp;&nbsp;
            <b>Organization:</b>
            {{ row.organization || organization }}
            <br />
          </span>
          <span v-if="row.bookingsPerYear || bookings" class="contact-row">
            &nbsp;&nbsp;
            <b>Bookings per Year:</b>
            {{ row.bookingsPerYear || bookings }}
            <br />
          </span>
          <span v-if="industries" class="contact-row">
            &nbsp;&nbsp;
            <b>Industry:</b>
            {{ industries }}
            <br />
          </span>
        </v-flex>
      </v-layout>
    </v-card-title>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { phoneFormatFilter } from '@/utils/phone'
import op from 'simple-object-path'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    customerAccount: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: String,
      default: () => undefined,
    },
    bookings: {
      type: Number,
      default: () => undefined,
    },
    industries: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      phoneFormatFilter,
      op,
    }
  },
  computed: {
    ...authComputed,
    customerAccountName() {
      return this.customerAccount?.name
    },
  },
  methods: {
    flexCall(row) {
      const name = row.customerName || ''
      const phone = row.customerPhone || ''
      const firstName = name.split(' ')[0]
      const lastName = name.split(' ')[1]
      const params = {
        customer: {
          firstName,
          lastName,
          phone,
        },
      }
      this.$store.dispatch('twilio/callNumber', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-detail {
  padding: 20px;
  margin-top: 0;
  background-color: $blue-pale;

  .contact-row {
    b {
      padding-right: 15px;
    }

    a {
      text-decoration: none;
    }
  }
}
</style>
