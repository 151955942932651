<template>
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-layout column sheet>
      <h1 class="page-header">Provider Payments</h1>
      <DataTable v-bind.sync="tableProps">
        <template #multiSelectActions>
          <v-menu>
            <template #activator="{ on }">
              <v-btn class="btn-secondaryaction" v-on="on">
                Payment Actions
                <v-icon>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-tile
                :id="`provider-payments-list-title-pay-provider`"
                @click="showDialog('payProvider')"
              >
                <v-list-tile-title>Pay Provider</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`provider-payments-list-title-add-charge`"
                @click="showDialog('addCharge')"
              >
                <v-list-tile-title>Add Charge</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`provider-payments-list-title-add-refund`"
                @click="showDialog('addRefund')"
              >
                <v-list-tile-title>Add Refund</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                :id="`provider-payments-list-title-reduce-charge`"
                @click="showDialog('reduceCharge')"
              >
                <v-list-tile-title>Reduce Charge</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </template>
      </DataTable>
    </v-layout>
    <PaymentSidebarWrapper
      :id="'provider-payments-list-payments-sidebar'"
      v-model="dialogType"
      :rows="tableProps.selectedRows"
      @close-modal="closeDialog"
      @remove-item="removeItem"
      @refresh="refresh"
    />
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import DataTable from '@/components/DataTable.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import providerPaymentsActionsWrapper from '@/components/ProviderPaymentsActionsWrapper'
import PaymentTableActions from '@/components/PaymentTableActions.vue'
import PaymentsListDetail from '@/components/PaymentsListDetail.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  textLike,
  userPredefined,
} from '@/utils/predefined'
import { mapActions } from 'vuex'

const reservationStatusMap = [
  { text: 'Upcoming', value: 'upcoming' },
  { text: 'Started', value: 'started' },
  { text: 'Finished', value: 'finished' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Hold', value: 'hold' },
]
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'fully_offered' },
  { text: 'Accepted', value: 'fully_accepted' },
  { text: 'Confirmed', value: 'fully_confirmed' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]
const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]

const ignoreReferralStatusForCategory = [
  'not_offered',
  'fully_offered',
  'fully_accepted',
  'fully_confirmed',
]
const categories = [
  {
    _t_id: 'cc4277cd',
    text: 'Payment Status',
    prop: 'paymentStatus',
    values: deepClone(paymentStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Referral Status',
    prop: 'referralStatus',
    values: deepClone(
      referralStatusMap.filter(
        (m) => !ignoreReferralStatusForCategory.includes(m.value)
      )
    ),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    DataTable,
    PaymentSidebarWrapper,
  },
  metaInfo() {
    return {
      title: 'Provider Payments',
    }
  },
  data() {
    return {
      dialogType: '',
      actionsDialog: false,
      showSidebarDialog: false,
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: true,
        total: 0,
        tableId: 'provider_payments_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detail: PaymentsListDetail,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        categories,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
        enableSelectableRows: true,
        enableSelectAll: true,
        selectedRows: [],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group.key === 'admin'
    await this.getColumns()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    removeItem(item) {
      this.tableProps.selectedRows = this.tableProps.selectedRows.filter(
        (selection) => {
          return selection.item.managedId !== item.managedId
        }
      )
    },
    closeDialog() {
      this.actionsDialog = false
      this.dialogType = ''
      this.refresh()
    },
    showDialog(type) {
      if (this.tableProps.selectedRows.length === 0) {
        return this.showAlert({
          type: 'error',
          message: 'You must select at least one reservation.',
        })
      }

      // check if selectedItems are from the same provider
      const differentReferrer = this.tableProps.selectedRows.find((item) => {
        return (
          item.item.referredBy !==
          this.tableProps.selectedRows[0].item.referredBy
        )
      })
      if (differentReferrer) {
        this.showAlert({
          message: 'Please select a single provider to proceed.',
          type: 'error',
        })
      } else {
        this.dialogType = type
      }
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getColumns() {
      const providerWrappedActions = await providerPaymentsActionsWrapper(
        PaymentTableActions,
        this.currentUser
      )
      const columnsOut = [
        {
          _t_id: '089d3f8b',
          prop: '/',
          component: providerWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: 'ad08f1c2-7568-4b24-8fd4-622fd776834e',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'managedId',
          defaultSort: true,
          detail: false,
        },
        {
          _t_id: 'ab65abb5',
          prop: 'paymentStatus',
          text: 'Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          type: 'text',
          filterType: 'contains',
          detail: false,
        },
      ]
      columnsOut.push(
        {
          _t_id: 'adcdd544',
          prop: 'lastDropoffDate',
          text: 'Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          detail: false,
        },
        {
          _t_id: '17d71ae2',
          prop: 'balance',
          text: 'Balance',
          computedText: (item) => currencyFilter(item),
          component: ReservationActionableColumn,
          displayType: 'action-item',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: 'cc04e82a',
          prop: 'customerName',
          text: 'Customer',
          sort: true,
          filter: true,
          component: ReservationActionableColumn,
          type: 'text',
          action: 'CUSTOMER_DETAIL',
          detail: false,
          displayType: 'action-item',
          sortProp: 'customerName',
          filterType: 'contains',
          childMethod: 'and',
          predefined: textLike,
        },
        {
          _t_id: '1791630b',
          prop: 'company',
          text: 'Referred To',
          component: ReservationActionableColumn,
          displayType: 'referred-to-basic-action',
          action: 'REFERRED_TO_DETAIL_BASIC',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          sortProp: 'company.name',
          filterProp: 'company.name',
          filterType: 'contains',
          childMethod: 'or',
          predefined: textLike,
        },
        {
          _t_id: 'b77dc3c4-12a3-4e42-bc6a-ec8fa7db7d54',
          prop: 'parentReservationManagedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'Reservation ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'parentReservation/managedId',
          filterProp: 'parentReservation/managedId',
          defaultSort: true,
          detail: false,
        },
        {
          _t_id: 'aee98743',
          prop: 'startDate',
          text: 'Pickup Date',
          computedText: (item) => DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          detail: false,
        },
        {
          _t_id: 'f72a351c',
          prop: 'customerTotal',
          text: 'Customer Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          defaultHidden: true,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: 'd025d361',
          prop: ['bookedByFirstName', 'bookedByLastName'],
          text: 'Booked By',
          component: ReservationActionableColumn,
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          action: 'BOOKED_BY_DETAIL',
          displayType: 'action-item',
          sortProp: 'bookedByLastName',
          filterType: 'contains',
          childMethod: 'and',
          predefined: userPredefined,
        },
        {
          _t_id: '37a7630b',
          prop: 'openForBid',
          text: 'Open For Bid',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          sortProp: 'trip.openForBid',
          filterProp: 'trip.openForBid',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Open' : 'Closed'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Open' : 'Closed'
          },

          predefined: [
            {
              text: 'Open',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Closed',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '712a430a',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: true,
          type: 'text',
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          method: 'and',
          filterType: 'eq',
          defaultHidden: true,
          detail: false,
          customFilterTabDisplay: (item) => (item ? 'Sent' : 'Not Sent'),
          predefined: [
            {
              text: 'Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'Not Sent',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'a9818d8d',
          prop: 'dueDate',
          sortProp: 'trip.dueDate',
          filterProp: 'trip.dueDate',
          text: 'Due Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          defaultHidden: true,
          detail: false,
        },
        {
          _t_id: 'e53ec354',
          prop: 'tripTypeLabel',
          sortProp: 'trip.tripType.label',
          filterProp: 'trip.tripType.label',
          text: 'Trip Type',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
          detail: false,
        },
        {
          _t_id: '1cd7a99f',
          prop: 'referralAmount',
          text: 'Referral Total',
          computedText: this.referralTotalComputedText,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sort: true,
          filter: true,
          type: 'number',
          sortProp: 'referralAmount',
          filterProp: 'referralAmount',
          filterType: 'contains',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: '483b58a8-6da9-48d3-a43a-1ef4d9d4211b',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '5a4b6e86',
          prop: 'reservationStatus',
          text: 'Reservation Status',
          computedText: (item) =>
            this.mapCategoryText(reservationStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          detail: false,
        },
        {
          _t_id: '32920d6e',
          prop: 'firstStopAddress',
          text: 'Pickup Location',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          childMethod: 'or',
          unset: ['6b01a5c0', 'c22dbed8'],
          predefined: [
            {
              text: 'Radius',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '9522531a',
                  text: 'Location Radius',
                  filterType: 'radius',
                  component: PickupRadiusFilter,
                },
              ],
            },
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                },
              ],
            },
          ],
        },
        {
          _t_id: '3b627c5d',
          prop: 'distance',
          filterProp: 'trip.distance',
          sortProp: 'trip.distance',
          text: 'Distance',
          computedText: (item) => metersToMilesString(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: '52a296fe',
          prop: 'drivingTime',
          filterProp: 'trip.drivingTime',
          sortProp: 'trip.drivingTime',
          text: 'Driving Time',
          computedText: (item) =>
            `${Number(
              Duration.fromObject({ seconds: item }.as('hours'))
            ).toFixed(2)} Hrs`,
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
          detail: false,
        },
        {
          _t_id: 'e9bbe02f',
          prop: 'firstSentDate',
          text: 'Referred Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultHidden: true,
          detail: false,
        },
        {
          _t_id: '11cd6d05',
          prop: 'cancelledOn',
          text: 'Cancelled Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
          detail: false,
        },
        {
          _t_id: '4464729b',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
          detail: false,
        },
        {
          _t_id: '424d105c-a3f7-4150-882d-27a7d1500427',
          prop: 'company/paymentMethod/label',
          text: 'Payment Method',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          detail: false,
          defaultHidden: true,
        }
      )

      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }
      this.tableProps.loading = true
      this.tableProps.selectedRows = []
      const reservationData = await this.$store.dispatch(
        'reservations/providerReferralsTableView',
        params
      )
      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
      this.tableProps.selectedRows = []
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.local() > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, referralAmount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(referralAmount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, referralAmount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
  },
}
</script>

<style lang="scss">
.cr-modal.v-card {
  border-radius: 10px !important;
}
</style>
