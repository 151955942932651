<template>
  <section>
    <div class="contact-detail">
      <v-card-title primary-title>
        <v-layout row wrap>
          <v-flex xs6>
            <div class="headline">
              {{ op(row.actionProps, 'name') }}
            </div>
            <div style="display: flex; align-items: center">
              <v-chip
                :color="$cr.theme.grayLight"
                label
                disabled
                style="right: 5px"
              >
                {{ op(row.actionProps, 'partnerType') }}
              </v-chip>
              <v-chip
                v-if="op(referredToCompany, 'onboardingStatusType')"
                :color="$cr.theme.grayLight"
                label
                disabled
                style="right: 5px"
              >
                {{ op(referredToCompany, 'onboardingStatusType') }}
              </v-chip>
              <CRIcon
                v-if="op(referredToCompany, 'spab')"
                height="42"
                width="42"
                style="margin: 0 0 -10px 8px"
                color="primary"
              >
                spab
              </CRIcon>
            </div>
            <span>
              Phone:
              <a
                :id="`${id}-referral-detail-href-phone-number`"
                :href="`tel:${op(row.actionProps, 'phone')}`"
              >
                {{ phoneFormatFilter(op(referredToCompany, 'companyPhone')) }}
              </a>
              <br />
            </span>
            <span>
              Email:
              <a
                :id="`${id}-referral-detail-href-email`"
                :href="`mailto:${op(referredToCompany, 'companyEmail')}`"
              >
                {{ op(referredToCompany, 'companyEmail') }}
              </a>
              <br />
            </span>
            <p>
              Address:
              {{ op(referredToCompany, 'address/addressName') }}
              <br />
            </p>
          </v-flex>
          <v-flex v-if="nonRejectedReferrals.length > 0" xs6>
            <v-layout row wrap>
              <v-flex
                v-for="(referral, referralKey) in nonRejectedReferrals.filter(
                  (referral) =>
                    op(referral, 'companyId') === op(row, 'actionProps/id') ||
                    op(referral, 'companyId') ===
                      op(row, 'actionProps/companyId')
                )"
                :key="referralKey"
                xs12
                fill-height
              >
                <v-layout
                  v-if="!showPaymentWindow"
                  row
                  wrap
                  referral-action-container
                >
                  <v-flex xs5 subheading referral-label>
                    <div>Referral # {{ referral.managedId }}</div>
                    <small class="referral-amount">
                      {{
                        currencyFilter(
                          (referral.referralStatus === 'reoffered'
                            ? referral.originalAmount
                            : referral.referralAmount) || 0
                        )
                      }}
                    </small>
                  </v-flex>

                  <v-flex xs7 text-xs-center>
                    <v-btn
                      v-if="op(referral, 'referralStatus') !== 'accepted'"
                      :id="`${id}-referral-detail-button-accept-referral-${referralKey}`"
                      :loading="loading"
                      class="btn-primaryaction"
                      @click="() => acceptReferral(referral.reservationId)"
                    >
                      Mark Accepted
                    </v-btn>
                    <CRButton
                      v-if="
                        isReferralRejectionReasonV2Enabled &&
                        op(referral, 'referralStatus') !== 'accepted'
                      "
                      :id="`${id}-referral-reject-button-${referralKey}`"
                      outline
                      text-color="red"
                      :loading="loading"
                      :disabled="!reservation.active"
                      @click="openRejectReferralSidebar(referral)"
                    >
                      Mark Declined
                    </CRButton>
                    <ReferralRejectDialog
                      v-else-if="op(referral, 'referralStatus') !== 'accepted'"
                      :id="`${id}-referral-detail-button-reject-referral-${referralKey}`"
                      :loading="loading"
                      :active="reservation.active"
                      outline
                      text="Mark Declined"
                      @reject="
                        (reasonType, notes) =>
                          rejectReferral(
                            referral,
                            referral.reservationId,
                            reasonType,
                            notes
                          )
                      "
                    />
                    <v-btn
                      v-if="op(referral, 'referralStatus') === 'accepted'"
                      :id="`${id}-referral-detail-button-accepted-${referralKey}`"
                      color="success"
                      outline
                    >
                      <v-icon>check_circle_outline</v-icon>
                      <strong>Accepted</strong>
                    </v-btn>
                    <v-btn
                      v-if="
                        op(referral, 'referralStatus') === 'accepted' &&
                        op(referral, 'balance') > 0
                      "
                      :id="`${id}-referral-detail-button-show-payment-window-${referralKey}`"
                      :loading="loading"
                      class="btn-primaryaction"
                      @click="showPaymentWindow = true"
                    >
                      <strong>Pay Affiliate</strong>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-form ref="pay-affiliate-form" :lazy-validation="true">
                  <v-layout
                    v-if="showPaymentWindow"
                    row
                    wrap
                    referral-action-container
                  >
                    <v-flex xs12 subheading referral-label>
                      <br />
                      <div>Record Affiliate Payment</div>
                      <br />
                    </v-flex>

                    <v-flex xs4 offset-xs1>
                      <CRInput
                        :id="`${id}-referral-detail-input-balance-${referralKey}`"
                        :value="referral.balance"
                        disabled
                        prefix="$"
                        label="Balance"
                      />
                    </v-flex>

                    <v-flex xs4 offset-xs2>
                      <CRInput
                        :id="`${id}-referral-detail-input-payment-amount-${referralKey}`"
                        v-model="paymentAmount"
                        label="Payment Amount"
                        :error-messages="paymentAmountErrors(referral)"
                        :rules="[
                          isRequired(true, isNotEmpty, {
                            req: 'Payment Amount Is Required',
                            error: 'Payment Amount Is Required',
                          }),
                        ]"
                        type="number"
                        prefix="$"
                      />
                    </v-flex>

                    <v-flex xs10 offset-xs1>
                      <CRSelect
                        :id="`${id}-referral-detail-select-payment-type-${referralKey}`"
                        v-model="paymentType"
                        :items="paymentTypes"
                        label="Payment Type"
                        item-text="label"
                        item-value="paymentTypeId"
                        :rules="
                          formSubmitted
                            ? [
                                isRequired(true, isNotEmpty, {
                                  req: 'Payment Type Required',
                                  error: 'Payment Type Required',
                                }),
                              ]
                            : []
                        "
                      />
                    </v-flex>

                    <v-flex xs10 offset-xs1>
                      <CRInput
                        :id="`${id}-referral-detail-input-reference-number-${referralKey}`"
                        v-model="refNumber"
                        label="Reference Number"
                        :rules="
                          formSubmitted
                            ? [
                                isRequired(true, isNotEmpty, {
                                  req: 'Reference Number Is Required',
                                  error: 'Reference Number Is Required',
                                }),
                              ]
                            : []
                        "
                      />
                    </v-flex>

                    <v-flex xs10 offset-xs1>
                      <CRInput
                        :id="`${id}-referral-detail-input-payment-notes-${referralKey}`"
                        v-model="paymentNotes"
                        multi-line
                        label="Payment Notes"
                        :rules="[
                          isRequired(true, isNotEmpty, {
                            req: 'Payment Note Is Required',
                            error: 'Payment Note Is Required',
                          }),
                        ]"
                      />
                    </v-flex>

                    <v-flex xs10 offset-xs1>
                      <v-checkbox
                        :id="`${id}-referral-detail-checkbox-send-payment-email-${referralKey}`"
                        v-model="sendPaymentEmail"
                        label="Send Payment Email"
                      />
                    </v-flex>

                    <v-flex xs10 offset-xs1 text-xs-right pay-affiliate-actions>
                      <v-btn
                        :id="`${id}-referral-detail-show-payment-window-${referralKey}`"
                        class="btn-secondaryaction"
                        @click="showPaymentWindow = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :id="`${id}-referral-detail-button-submit-form-pay-${referralKey}`"
                        class="btn-primaryaction"
                        :disabled="
                          referral.balance < parseFloat(paymentAmount || 0)
                        "
                        @click="() => submitForm(referral, referralKey)"
                      >
                        Pay
                      </v-btn>
                      <br />
                      <br />
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="responseMessage" row>
                    <v-flex class="text-xs-center red--text">
                      {{ responseMessage }}
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-title>
    </div>
    <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
  </section>
</template>

<script lang>
import op from 'simple-object-path'
import * as logger from '@/utils/logger'
import { phoneFormatFilter } from '@/utils/phone'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import {
  isNotEmpty,
  isRequired,
  validateGreaterThanZero,
} from '@/utils/validators'
import { mapActions } from 'vuex'
import ReferralRejectDialog from '../components/ReferralRejectDialog.vue'
import customerAccounts from '@/services/customerAccounts'
import { mapGetters } from 'vuex'
import CRButton from '@/cr/components/CRButton.vue'

export default {
  components: {
    ReferralRejectDialog,
    CRButton,
  },
  props: {
    reservation: {
      type: Object,
      default: () => undefined,
    },
    row: {
      type: Object,
      default: () => undefined,
    },
    customerAccount: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      op,
      phoneFormatFilter,
      currencyFilter,
      paymentTypes: [],
      paymentType: '',
      isNotEmpty,
      isRequired,
      validateGreaterThanZero,
      sendPaymentEmail: true,
      paymentMethod: undefined,
      paymentAmount: undefined,
      refNumber: '',
      paymentNotes: '',
      loading: false,
      formSubmitted: false,
      responseMessage: '',
      showPaymentWindow: false,
      referredToCompany: undefined,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      referralsTVFilters: 'referrals/referralsTVFilters',
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
    nonRejectedReferrals() {
      if (!this.reservation) return
      const referralsList = []

      if (this.reservation.referredTo && this.reservation.referredTo.length) {
        this.reservation.referredTo.forEach((referralItem) => {
          if (!this.reservation.referredTo) return
          this.reservation.referredTo.forEach((resReferralItem, index) => {
            if (
              op(resReferralItem, 'companyId') === referralItem.id ||
              op(resReferralItem, 'companyId') === referralItem.companyId
            ) {
              const currentRef = Object.assign(
                this.reservation.referredTo[index]
              )
              currentRef.managedId = referralItem.managedId
              currentRef.reservationId = referralItem.reservationId
              referralsList.push(currentRef)
            }
          })
        })
      }
      return referralsList || this.reservation.referrals
    },
  },
  watch: {
    reservation() {
      this.setReferredToData()
    },
  },
  async mounted() {
    this.setReferredToData()

    // get payment method types
    const { data: paymentTypes } = await this.$store.dispatch(
      'types/getBillingPaymentTypes'
    )
    this.paymentTypes = paymentTypes
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    setReferredToData() {
      this.referredToCompany = (op(this.reservation, 'referredTo') || []).find(
        (r) =>
          r.companyId === parseInt(this.row.actionProps.referredToCompanyId)
      )
    },
    async rejectReferral(referral, referralId, reasonType, notes) {
      this.loading = true

      try {
        const params = {
          reservationId: referral.reservationId,
          body: {
            referralRejectionReasonTypeId: reasonType,
            notes: notes,
          },
        }
        // reject the current referral
        await this.$store.dispatch('reservations/rejectReferral', params)
        this.$emit('refresh-query-request')
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error rejecting referral, please try again',
        })
        logger.error(err)
        console.log(err)
        this.loading = false
      }
      this.loading = false
    },
    async acceptReferral(reservationId) {
      this.loading = true
      const params = {
        reservationId,
      }
      let customerAccount = this.customerAccount
      if (customerAccount && customerAccount.enterpriseAccountId) {
        const { data } = await customerAccounts.getCustomerAccount(
          customerAccount.enterpriseAccountId
        )
        customerAccount = data
      }
      if (customerAccount && customerAccount.useCustomReferralTerms) {
        await this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: `Provider Acceptance Required for ${customerAccount.referralTermsName}. Contact the provider to accept this referral.`,
        })
        this.loading = false
        return
      }
      try {
        await this.$store.dispatch('reservations/acceptReferral', params)
        this.$emit('refresh-query-request')
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error accepting referral, please try again',
        })
        logger.error(err)
        this.loading = false
      }
      this.loading = false
    },
    submitForm(referral, referralKey) {
      this.formSubmitted = true
      setTimeout(async () => this.submitPayment(referral, referralKey), 0)
    },
    async submitPayment(referral, referralKey) {
      const formList = this.$refs['pay-affiliate-form']
      if (formList[referralKey] && formList[referralKey].validate) {
        const isValid = formList[referralKey].validate()
        if (!isValid) {
          return
        }
      } else {
        return
      }
      this.loading = true
      const params = {
        paymentType: this.paymentType,
        // TODO: change the payment system when we want to do payments via authorize.net/plaid/etc
        paymentSystem: 2,
        userId: this.currentUser.userId,
        reservationIds: [referral.reservationId],
        companyId: this.currentUser.companyId,
        referenceNumber: this.refNumber,
        amount: parseFloat(this.paymentAmount || 0),
        sendEmail: this.sendPaymentEmail,
        notes: {
          note: this.paymentNotes,
        },
      }
      let res
      try {
        res = await this.$store.dispatch('payments/addReferralPayment', params)
      } catch (err) {
        this.loading = false
        this.reponseMessage = 'Error Submitting Payment, Please try again'
        logger.error(err)
      }

      if (!res || !res.data.successful) {
        this.loading = false
        this.responseMessage =
          res.data && res.data.message
            ? res.data.message
            : 'Error Submitting Payment, Please try again'
        logger.error(res)
      } else {
        this.showAlert({
          message: 'Provider successfully paid!',
        })
        this.loading = false
        setTimeout(() => this.$emit('refresh-query-request'), 2000)
      }
    },
    paymentAmountErrors(referral) {
      const errors = []
      if (referral.balance < parseFloat(this.paymentAmount || 0)) {
        errors.push(
          `Payment Amount Cannot Exceed ${currencyFilter(
            referral.balance || 0
          )}`
        )
      }
      if (
        parseFloat(this.paymentAmount || 0) <= 0 &&
        this.formSubmitted === true
      ) {
        errors.push('Payment Amount Must Be Greater Than Zero')
      }
      return errors
    },
    openRejectReferralSidebar(referral) {
      const component = () => import('./ReferralRejectSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          reservation: this.reservation,
          referral,
          title: `Reject Referral`,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.contact-detail {
  margin-top: 0;
}

.referral-action-container {
  align-content: center;
  min-height: 100px;
  border: 1px solid $gray-light;
  border-radius: 4px;

  .referral-label {
    padding-left: 8%;
    margin-top: 2px;

    .referral-amount {
      color: gray;
    }
  }
}

.pay-affiliate-actions {
  margin-left: 1.5%;
}
</style>
