<template>
  <v-layout class="action-links" row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :key="idx"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span v-on="action.on">
            <v-icon
              v-if="item.click"
              :class="item.icon()"
              :color="item.color ? item.color() : 'primary'"
              @click="item.click"
            >
              {{ item.icon() }}
            </v-icon>

            <router-link
              v-if="item.path"
              target="_blank"
              :to="{ path: item.path() }"
              class="action-link"
            >
              <v-icon
                :class="item.icon()"
                :color="item.color ? item.color() : 'primary'"
              >
                {{ item.icon() }}
              </v-icon>
            </router-link>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div>
      <v-menu bottom transition="slide-x-transition">
        <template #activator="more">
          <v-icon color="primary" v-on="more.on">more_vert</v-icon>
        </template>
        <v-list>
          <template
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
          >
            <v-list-tile
              v-if="item.condition ? item.condition() : true"
              :id="`refunds-table-row-${rowProps.index}-action-${i}`"
              :key="i"
              @click="handleClick(item)"
            >
              <v-list-tile-title>
                <div class="action-link">
                  <v-icon
                    v-if="item.icon"
                    :color="item.color ? item.color() : 'primary'"
                  >
                    {{ item.icon() }}
                  </v-icon>
                  <span class="action-text">{{ item.title() }}</span>
                </div>
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path() })
      } else if (item.href) {
        window.open(item.href(), '_blank')
      } else if (item.click) {
        item.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-links {
  height: 22px;
}

.action-link,
.action-links a {
  display: flex;
  align-items: center;
}

.action-text {
  padding-left: 8px;
  font-size: 12px;
  text-decoration: none;
  color: $primary;
}

.action-link-container {
  height: 22px;
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }

  i {
    width: 22px;
    font-size: 22px;
  }
}
</style>
