<template>
  <v-container>
    <template v-if="row.action === 'REFERRED_TO_DETAIL'">
      <ReservationReferralDetail
        :row="row"
        :reservation="reservation"
        @refresh-query-request="refreshQuery"
      />
    </template>
    <template v-if="row.action === 'REFERRED_TO_DETAIL_BASIC'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              {{ row.company.name }}
            </div>

            <span>
              Phone:
              <a :href="`tel:${row.company.opsPhone}`">
                {{ row.company.opsPhone }}
              </a>
              <br />
            </span>
            <span>
              Email:
              <a :href="`mailto:${row.company.email}`">
                {{ row.company.email }}
              </a>
              <br />
            </span>
            <span>
              Address:
              {{ row.company.address }}
              <br />
            </span>
          </div>
        </v-card-title>
      </div>

      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <CustomerDetail
      v-if="row.action === 'CUSTOMER_DETAIL'"
      :row="customer"
      :customer-account="customerAccount"
      :bookings="bookings"
      :industries="industries"
      :organization="organization"
    />
    <template v-if="row.action === 'BOOKED_BY_DETAIL'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              {{ op(reservation, 'bookedBy/firstName') }}
              {{ op(reservation, 'bookedBy/lastName') }}
            </div>

            <span>
              Email:
              <a :href="`mailto:${op(reservation, 'bookedBy/email')}`">
                {{ op(reservation, 'bookedBy/email') }}
              </a>
              <br />
            </span>
          </div>
        </v-card-title>
      </div>

      <v-progress-linear v-if="loading" :indeterminate="true" height="2" />
    </template>
    <template v-if="row.action === 'ADD_ACCOUNT_CREDIT'">
      <v-container>
        <AddAccountCredit :row="row" @row-collapse="collapseRow" />
      </v-container>
    </template>

    <PaymentSidebarWrapper
      v-model="sidebarAction"
      :row="row"
      @row-collapse="collapseRow"
      @close-modal="collapseRow"
    />
  </v-container>
</template>

<script>
import customers from '@/services/customers'
import customerAccounts from '@/services/customerAccounts'
import { phoneFormatFilter } from '@/utils/phone'
import { snakeToCamel } from '@/utils/string'
import { authComputed } from '@/state/helpers'
import op from 'simple-object-path'
import ReservationReferralDetail from '@/components/ReservationReferralDetail.vue'
import AddAccountCredit from '@/components/AddAccountCredit.vue'
import CustomerDetail from '@/components/CustomerDetail.vue'
import PaymentSidebarWrapper from '@/components/PaymentSidebarWrapper.vue'

export default {
  components: {
    ReservationReferralDetail,
    AddAccountCredit,
    CustomerDetail,
    PaymentSidebarWrapper,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reservation: {},
      reservationV2: {},
      childReservationV2: {},
      loading: false,
      op,
      phoneFormatFilter,
      sidebarAction: '',
      customerAccount: null,
      customer: {},
      organization: null,
      industries: null,
      bookings: null,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    'row.action': {
      immediate: true,
      handler: function (action) {
        if (
          action === 'ADD_CHARGE' ||
          action === 'REDUCE_CHARGE' ||
          action === 'ADD_REFUND' ||
          action === 'CHANGE_DUE_DATE' ||
          action === 'COLLECT_PAYMENT' ||
          action === 'INVOICE' ||
          action === 'PAY_PROVIDER'
        ) {
          this.sidebarAction = snakeToCamel(action.toLowerCase())
        }
      },
    },
  },
  async mounted() {
    this.loading = true
    this.$emit('row-expanded', this.props)
    await this.getReservation()
    this.loading = false
  },
  methods: {
    async getReservation() {
      let { reservationId, parentReservationId } = this.row
      parentReservationId = parentReservationId || reservationId
      const shouldGetChild = parentReservationId !== reservationId

      const reservationData = await this.$store.dispatch(
        'reservations/reservationById',
        parentReservationId || reservationId
      )

      const reservationV2Data = await this.$store.dispatch(
        'reservations/reservationv2ById',
        {
          reservationId: parentReservationId || reservationId,
          viewRejectedReferrals: true,
        }
      )

      let childReservationV2Data = null
      if (shouldGetChild) {
        childReservationV2Data = await this.$store.dispatch(
          'reservations/reservationv2ById',
          { reservationId }
        )
      } else {
        childReservationV2Data = reservationV2Data
      }

      const reservation = reservationData?.data
      const reservationV2 = reservationV2Data?.data
      const childReservationV2 = childReservationV2Data?.data
      let customerData = null
      if (reservationV2.customerId) {
        customerData = await customers.getCustomer(reservationV2.customerId)
        this.customer = { ...this.row, customer: customerData?.data?.customer }
      } else {
        this.customer = {
          ...this.row,
          customer: {
            firstName: reservationV2.customerName,
            phone: reservationV2.customerPhoneNumber,
            email: reservationV2.customerEmail,
          },
        }
      }
      this.reservation = reservation
      this.reservationV2 = reservationV2
      this.childReservationV2 = childReservationV2

      if (
        customerData?.data?.customer?.customerId !==
        childReservationV2.customerId
      ) {
        const { data } = await customers.getCustomer(
          this.childReservationV2.customerId
        )
        customerData = data
      } else {
        customerData = customerData?.data
      }
      if (customerData?.customer?.customerAccountId) {
        const {
          data: customerAccountData,
        } = await customerAccounts.getCustomerAccount(
          customerData?.customer?.customerAccountId
        )
        this.customerAccount = customerAccountData
      }
      this.organization = customerData?.customer?.organization
      this.industries = (customerData?.customer?.industries || '')
        .split('||')
        .join(', ')
      this.bookings = customerData?.customer?.bookings
    },
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
    collapseRow() {
      this.refreshQuery()
      this.props.expanded = false
      this.sidebarAction = ''
    },
  },
}
</script>

<style lang="scss">
.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.padded {
  padding: 20px;
}

.float-right {
  float: right;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
</style>
