/**
 * Converts a distance from meters to miles.
 *
 * @param meters - The distance in meters to convert. Defaults to 0 if not provided.
 * @returns The distance converted to miles as a number.
 */
export const metersToMiles = (meters: number = 0): number => {
  return meters * 0.000621371
}

/**
 * Converts a distance from meters to a formatted string representing miles.
 *
 * This function converts the input distance from meters to miles and formats
 * the result as a string with one decimal place, followed by " mi.".
 *
 * @param meters - The distance in meters to convert. Defaults to 0 if not provided.
 * @returns The distance converted to miles as a formatted string, e.g., "5.0 mi.".
 */
export const metersToMilesString = (meters: number = 0): string => {
  return `${metersToMiles(meters).toFixed(1)} mi.`
}
