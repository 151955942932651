
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import op from 'simple-object-path'
import { columnDisplay } from '@/utils/columnDisplay'
import {
  buildReservationPath,
  reservationViewPath,
} from '@/utils/reservationPathBuilder'
import { truncate } from '@/utils/string'
import { TableViewReservation } from '@/models/dto/Reservation'
import { DataTableColumn } from '@/models/DataTableColumn'

type ReferredToItem = {
  name: string
  companyId: number
  companyName: string
  referredToCompanyId: number
  partnerType: string
  spab: string
}

@Component({})
export default class ReservationActionableColumn extends Vue {
  @Prop({ type: Object, default: () => ({}) }) readonly row: TableViewReservation
  @Prop({ type: Object, default: () => ({}) }) readonly rowProps: any
  @Prop({ type: Object, default: () => ({}) }) readonly column: DataTableColumn
  @Prop({ type: Function, default: () => null }) readonly handleAction: any
  @Prop({ type: String, default: null }) readonly id: string

  resStatusMap: Record<string, string> = {
    finished: 'completed',
    started: 'in-progress',
    cancelled: 'upcoming',
    hold: 'upcoming',
  }
  actionList: any[] = []
  referredToList: ReferredToItem[] = []
  referredByList: any[] = []
  hasResDetail: boolean = false
  loading: boolean = false
  action: string = ''

  op = op
  truncate = truncate
  columnDisplay = columnDisplay
  buildReservationPath = buildReservationPath
  reservationViewPath = reservationViewPath

  mounted(): void {
    this.assignDefaults()
    this.hasResDetail = false
    this.getResDetail()
  }

  beforeUpdate(): void {
    this.getResDetail()
    this.hasResDetail = false
    this.assignDefaults()
  }

  async getResDetail(): Promise<void> {
    const referredTo =
      typeof this.row?.referredTo === 'string' ? this.row.referredTo : ''
    const listOfCompanies = referredTo.split('::')
    this.referredToList = listOfCompanies.map((r) => {
      const companyId = parseInt(r.split('|')?.[0], 10) || 0
      const name = r.split('|')?.[1]
      const partnerType = r.split('|')?.[2]
      const spab = r.split('|')?.[3]
      const hasOperatorRequested = r.split('|')?.[4]
      return {
        name,
        companyId,
        companyName: name,
        referredToCompanyId: companyId,
        partnerType,
        spab,
        hasOperatorRequested
      }
    })

    const referredBy = this.row?.referredBy || ''
    this.referredByList = referredBy.split('::')
  }

  async assignDefaults(): Promise<void> {
    this.action = this.column.action
    if (this.column.list) {
      this.actionList = op(this.row, this.column.list) || []
    }
  }

  getReferredToString(item: ReferredToItem): string {
    return this.column.listProp && this.column.listProp.length === 2
      ? op(item, this.column.listProp[0]) +
          ' ' +
          op(item, this.column.listProp[1])
      : truncate(op(item, this.column.listProp) || '', 14, false)
  }

}
