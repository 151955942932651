import { render, staticRenderFns } from "./ReservationReferralDetail.vue?vue&type=template&id=25c47f3d&scoped=true"
import script from "./ReservationReferralDetail.vue?vue&type=script&lang=true"
export * from "./ReservationReferralDetail.vue?vue&type=script&lang=true"
import style0 from "./ReservationReferralDetail.vue?vue&type=style&index=0&id=25c47f3d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c47f3d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VCardTitle,VCheckbox,VChip,VFlex,VForm,VIcon,VLayout,VProgressLinear})
